<template>
  <div class="decision-denied">
    <Container>
      <Card padding="large">
        <b-row>
          <b-col cols="12" sm="auto" class="text-center">
            <CloseSVG class="icon h1 text-error" />
          </b-col>
          <b-col>
            <Margins>
              <h1 class="h3 text-normal">{{ $t('SORRY_BUT_DECLINED') }}</h1>

              <p class="text-small">{{ $t('DECLINED_REASONS_TITLE') }}</p>
              <List :items="reasons" variant="bullets" class="text-small" />

              <!--              <template v-if="!backOfficeLoggedIn">-->
              <!--                <Separator size="small" />-->

              <!--                <p class="text-strong">-->
              <!--                  {{ $t('LOAN_APPLICATION_DENIED.FINANCE_TEXT') }}-->
              <!--                  - -->
              <!--                  <Button-->
              <!--                    variant="link"-->
              <!--                    color="primary"-->
              <!--                    @click="-->
              <!--                      $router.push({ name: ROUTES.CREDIT_CARD_PAYMENT.name })-->
              <!--                    "-->
              <!--                    >{{ $t('LOAN_APPLICATION_DENIED.CLICK_HERE') }}</Button-->
              <!--                  >-->
              <!--                </p>-->
              <!--              </template>-->

              <Separator size="large" />
              <div class="text-center">
                <Button @click="$router.push({ name: ROUTES.HOME.name })">{{
                  $t('RETURN_TO_HOME')
                }}</Button>
              </div>
            </Margins>
          </b-col>
        </b-row>
      </Card>
    </Container>
  </div>
</template>

<script>
import {
  Button,
  Card,
  Container,
  List,
  Margins,
  Separator,
} from '@/components';
import CloseSVG from '@/assets/images/times-circle-regular.svg';
import { constants } from '@/mixins';
import { getDynamicTranslation } from '@/utils';
import { mapState } from 'vuex';

export default {
  name: 'Denied',
  mixins: [constants],
  components: {
    Container,
    Card,
    Margins,
    List,
    Button,
    Separator,
    CloseSVG,
  },
  computed: {
    ...mapState(['backOfficeLoggedIn']),
    reasons() {
      const reasonsTranslations = getDynamicTranslation(
        this.$i18n,
        'DECLINED_REASONS',
      );

      return Object.values(reasonsTranslations).map((r) => r);
    },
  },
};
</script>
